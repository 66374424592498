.sidebar-logo-container {
    width: 80px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sidebar-logo {
    width: 50px;
}
