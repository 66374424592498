.formField {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.formFieldLabel {
    margin-left: 10px;
    margin-right: 5px;
}

.formFieldLabelLeft {
    margin-right: 5px;
}