.VEHICLE_PACKAGE_TAG {
    display: "flex";
    align-items: "center";
    justify-content: "center";
    height: 20;
    padding: 8px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: #A020F0;
    font-size: 11px;
}