.icon {
    width: 35px;
    height: 35px;
    background-color: white;

    border: 1px solid lightgray;
}
.iconMui {
    width: 35px;
    height: 35px;
    padding: 5px;
    background-color: white;

    border: 1px solid lightgray;
}