.address {
    padding: 0;
    height:2em;
}

.GEOFENCE {
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    color: black;
    background-color: #A020F0;
    font-size: 10px;
}

.GEOFENCE_Description {
    font-size: 12px;
    font-weight: bold;
    color: #A020F0;
}
